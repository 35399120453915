:root {
    --primary-color: #4a90e2;
    --secondary-color: #f5a623;
    --text-color: #333;
    --background-color: #f8f9fa;
    --card-background: #ffffff;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
  }
  
  .landing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .login-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .main-content {
    margin-top: 50px;
  }
  
  .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
  }
  
  .hero-text {
    flex: 1;
    padding-right: 50px;
  }
  
  .hero-image {
    flex: 1;
  }
  
  .hero-image img {
    max-width: 100%;
    height: auto;
  }
  
  .hero-title {
    font-size: 48px;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 24px;
    margin-bottom: 20px;
    min-height: 3em;
  }
  
  .typewriter-text {
    display: inline-block;
  }
  
  .cursor {
    display: inline-block;
    width: 2px;
    height: 1.2em;
    background-color: var(--primary-color);
    margin-left: 2px;
    animation: blink 0.7s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .hero-description {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .cta-buttons {
    display: flex;
    gap: 20px;
  }
  
  .primary-cta, .secondary-cta {
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
  
  .primary-cta {
    background-color: var(--primary-color);
    color: white;
    border: none;
  }
  
  .secondary-cta {
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
  
  .section-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  
  .feature-card {
    background-color: var(--card-background);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .feature-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .footer {
    margin-top: 100px;
    text-align: center;
    padding: 20px 0;
    background-color: var(--primary-color);
    color: white;
  }
  
  @media (max-width: 768px) {
    .hero-section, .feature-grid {
      flex-direction: column;
      grid-template-columns: 1fr;
    }
  
    .hero-text, .hero-image {
      padding-right: 0;
      margin-bottom: 30px;
    }
  
    .cta-buttons {
      flex-direction: column;
    }
  }