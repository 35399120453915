:root {
    --primary-color: #3b5998;
    --secondary-color: #8b9dc3;
    --tertiary-color: #dfe3ee;
    --background-color: #f7f7f7;
    --white-color: #ffffff;
    --text-color: #333333;
    --typewriter-color: #e67e22;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
  }
  
  .login-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  .login-card {
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .login-title {
    color: var(--primary-color);
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .login-subtitle {
    color: var(--text-color);
    font-size: 1.2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .typewriter-line {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .typewriter-container {
    display: inline-flex;
    align-items: center;
  }
  
  .typewriter-text {
    color: var(--typewriter-color);
    font-weight: bold;
  }
  
  .cursor {
    display: inline-block;
    width: 2px;
    height: 1.2em;
    background-color: var(--primary-color);
    margin-left: 2px;
    animation: blink 0.7s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .login-button {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    border-radius: 25px;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .login-button:hover {
    background-color: var(--secondary-color);
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  
  .login-signup {
    margin-top: 1rem;
    color: var(--text-color);
  }
  
  .login-signup a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-signup a:hover {
    text-decoration: underline;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    width: 100%;
    margin-top: 2rem;
  }
  
  .feature-card {
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .feature-card h3 {
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-card p {
    color: var(--text-color);
    font-size: 0.9rem;
  }
  
  .login-footer {
    margin-top: 2rem;
    color: var(--text-color);
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .features-grid {
      grid-template-columns: 1fr;
    }
  }

  @keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.animate-blink {
  animation: blink 1s infinite;
}